body {
    font-family: "Trebuchet MS", Arial, sans-serif !important;
    background-color: #f4f3f3 !important;
}

/* Auth Page Start */

.authPageContainer {
    background-color: #f4f3f3 !important;
    background-size: auto !important;
}
.logoContainer {
    margin-bottom: 71px;
}
.loginlogo {
    width: 183px !important;
    height: 59px !important;
}
.loginCenterContainer {
    width: 420px;
    max-width: 90% !important;
    margin: 0 auto;
}
.authPageContainer label {
    font-family: "Trebuchet MS", Arial, sans-serif;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18.62px !important;
    color: #000 !important;
}
.authPageContainer input {
    background-color: white !important;
    border: 1px solid #efefef !important;
    color: #000 !important;
}
.authPageContainer input::placeholder {
    font-family: "Trebuchet MS", Arial, sans-serif;
    font-size: 14px;
}

#kt_sign_in_submit {
    background: #bb2e39 !important;
    border-radius: 6px !important;
}

#kt_sign_in_submit .indicator-label {
    font-family: "Trebuchet MS", Arial, sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 16.26px;
    text-align: center;
}
.rememberContainer {
    label {
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        display: inline-block;
    }
    .checkbox {
        border: 1px solid #808080;
        box-sizing: border-box;
        border-radius: 3px;
        width: 16px;
        height: 16px;
        margin-right: 6px;
    }
    .rememberText {
        font-family: "Trebuchet MS", Arial, sans-serif;
        font-size: 12px;
        color: #808080;
    }
}

/* Auth Page End */

/* Home Page Start */

#kt_header {
    background-color: #bb2e39 !important;
    height: 50px;
}
.card {
    border: 1px solid #efefef !important;
    border-radius: 8px !important;
}
.statNumber {
    font-size: 36px !important;
}

.chartDescription {
    font-size: 14px;
    font-weight: 400;
    margin: 16px 24px 9px 24px;
}

/* Home Page End */

/* Table Start */
.tableContainer .table-responsive {
    border: solid 1px #dcdcdc;
    border-bottom: none;
    border-radius: 6px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.tableContainer .myTable {
    font-family: "Inter", sans-serif;
    margin: 0;

    thead tr th {
        height: 47px;
        padding: 16px !important;
        font-weight: 600;
        font-size: 12px;
        line-height: 14.52px;
        border: solid 1px #dcdcdc;
        border-top: none;
        color: #000;
        white-space: nowrap;
        background: #f4f3f3;
        position: relative;

        .tableHeaderIcon {
            position: absolute;
            top: 14px;
            left: 32%;
        }

        &.postBg {
            background: linear-gradient(0deg, rgba(187, 46, 57, 0.04), rgba(187, 46, 57, 0.04)), #f4f3f3;
        }

        &:first-child {
            position: -webkit-sticky;
            position: sticky;
            z-index: 1111;
            padding: 0 !important;
            left: 0px;
            border-right: solid 1px #f4f3f3 !important;

            border-left: none;
            .container {
                padding: 16px !important;
                width: 100%;
                height: 100%;
                display: block;
                border-right: solid 1px #dcdcdc;
            }
        }
        &:last-child {
            border-right: none;
        }
    }
    tbody tr {
        td {
            background-color: white;
            height: 40px;
            padding: 12px !important;
            font-size: 12px !important;
            font-weight: 500 !important;
            border: solid 1px #dcdcdc;
            color: #000;
            white-space: nowrap;
            &:first-child {
                position: -webkit-sticky;
                position: sticky;
                z-index: 1111;
                left: 0px;
                border-left: none;
                padding: 0 !important;
                border-right: solid 1px #ffffff !important;

                .container {
                    padding: 12px !important;
                    width: 100%;
                    height: 100%;
                    display: block;
                    border-right: solid 1px #ddd;
                }
            }
            &:last-child {
                border-right: none;
            }
            a {
                color: #bb2e39 !important;
            }
        }
        &:last-child td {
            border-bottom: solid 1px #dcdcdc !important;
        }
    }
}
/* Table End */

.tfoot {
    padding: 13px 0px;
    border: solid 1px #dcdcdc !important;
    border-radius: 6px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
/* Patient Detail*/
.userInfoContainer {
    font-family: "Inter", sans-serif;
    color: #000;

    .patientPic {
        width: 130px;
        height: 130px;
        margin-right: 16px;
        border-radius: 13px;
    }
    .patientName {
        font-size: 20px;
        margin-right: 16px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 16px;
    }
    .lastActive {
        font-size: 12px;
        margin-right: 16px;
        font-weight: 400;
        line-height: 14px;
        margin-bottom: 16px;
    }

    .subHead {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        margin-right: 8px;
        margin-bottom: 19px;
    }

    .subContent {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 19px;
        margin-right: 34px;
    }

    .leftSide {
        margin-right: 100px;
    }
}

.identifiedTriggers {
    font-family: "Inter", sans-serif;
    .iBox {
        background-color: white;
        border: 1px solid #efefef;

        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        flex: 1 1 auto;
        margin-right: -1px;
        max-width: 210px;
        width: 210px;
        &.passive {
            opacity: 0.5;
            pointer-events: none; // Click Prevent for Passive Link
        }
        span {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #000;
        }
        svg {
            width: 21px;
        }
    }
}

.headerTriggerIcon {
    margin-right: 16px;
    margin-bottom: 16px;
    svg {
        width: 21px !important;
        width: 18px !important;
    }
}
/* Patient Detail End*/

#kt_header {
    z-index: 11111;
}

.statNumber {
    color: #181c32 !important;
}
