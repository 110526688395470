.pagination-container {
    display: flex;
    list-style-type: none;
    font-family: 'Inter',sans-serif;
    font-size: 14px;
    margin:0;
  
    .pagination-item {
      padding: 0 12px;
      height: 32px;
      text-align: center;
      margin: auto 4px;
      color: rgba(108, 108, 108, 0.6);
      display: flex;
      box-sizing: border-box;
      align-items: center;
      letter-spacing: 0.01071em;
      border-radius: 16px;
      line-height: 24px;
      font-size: 14px;
      min-width: 32px;
  
      &.dots:hover {
        //background-color: transparent;
        cursor: default;
      }
      &:hover {
        //background-color: rgba(0, 0, 0, 0.04);
        font-weight: 700;
        cursor: pointer;
      }
  
      &.selected {
        font-weight: 700;
        color:#05070A;
      } 
  
      &.disabled {
        pointer-events: none;
  
        .arrow::before {
          border-right: 0.12em solid rgba(0, 0, 0, 0.43);
          border-top: 0.12em solid rgba(0, 0, 0, 0.43);
        }
  
        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }

  .paginateDescription
  {
    font-family: 'Inter', sans-serif;
    white-space: nowrap;
    font-size: 14px;
    color:#5B6676;
  }