.tabContainer {
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 25px;
    display: flex;
    gap: 24px;
    font-size: 14px;
    line-height: 18px;

    & .tabContent {
        padding-bottom: 0px;
        color: #656567;
        cursor: pointer;
        &.active {
            font-weight: 700;
            color: #bb2e39;
            border-bottom: 2px solid #BB2E39;

        }
    }
}
